@import '../../variables.sass'

.button
    position: absolute
    bottom: 100px
    right: 40px
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    border-radius: 50%
    background-color: $color_cyan
    cursor: pointer
    z-index: 10000

.invisible
    display: none    